.nav-link {
  font-size: 12px;
}

.btn-search-bt {
  position: absolute !important;
  bottom: 0;
}

.modal-lg-cus {
  max-width: 80% !important;
}

.div-bg-gray {
  background:#f0f3f5 !important;
}

.center-ver {
  display: flex;
  align-items: center;
}

.clr-red {
  color: red !important;
}

.pd-5 {
  padding: 5px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mg-bt-5 {
  margin-bottom: 5px !important;
}

.md-foo-pd {
  padding: 1rem 0 0 0 !important;
}

.fsize10 {
  font-size: 10px !important;
}

.fsize12 {
  font-size: 12px !important;
}

.fsize14 {
  font-size: 14px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.fweight-bold {
  font-weight: bold !important;
}
.cke_dialog_background_cover{
  background-color: #2f353a !important;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container, .ng2-dropdown-menu {
  z-index: 99999 !important;
}

table.table-striped td.bioContent p img {
  max-width: 100% !important;
  color: red;
}

.label-bold {
  font-weight: bold !important;
  color: #495057 !important;
}

.sidebar .nav-link:hover{
  color: #fff !important;
  background: #20a8d8 !important;
}

.sidebar .nav-link:hover .nav-icon{
  color: #fff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.mg-top-5 {
  margin-top: 5px;;
}

.mg-right-45 {
  margin-right: 45px;
}