
/* Draggable*/

.drag-border {
    border: #ff525b dashed 2px;
}

.drag-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.drag-handle:active { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

/* Droppable */

.drag-hint-border {
    border: #3c763d dashed 2px;
}

.drag-over-border {
    border: #fbbc05 dashed 2px;
}

.drag-transit {
    border: #3500FF dashed 2px;
}